<script>
export default {
  metaInfo: {
    title: "Inbound Connections",
  }
};
</script>

<script setup>
import ConnectionsView from '@/components/basic/ConnectionsView.vue';
</script>

<template>
  <ConnectionsView url="?f=configuration&f2=inboundConnections&p=5G&c_subType=LMF"/>
</template>

<style scoped>

</style>